// /src/store/actions/user.ts
import { loadingController } from '@ionic/core';
import { TypeKeys } from '../actionTypes';

declare var firebase: any;

export const initializeApplication = () => (dispatch, _getState) => {
  const firebaseConfig = {
    apiKey: 'AIzaSyDYJ-WRw_BuG2Xxm5GVKCeXy3BQdWP5lUA',
    authDomain: 'plug-and-x.discovery.wirelesscar.cloud',
    projectId: 'plug-and-x-app',
    storageBucket: 'plug-and-x-app.appspot.com',
    messagingSenderId: '863138854418',
    appId: '1:863138854418:web:1d35caea2dda07852b4acb',
  };

  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  firebase.auth().onAuthStateChanged(async user => {
    if (user) {
      // User is signed in.
      const loader: any = await loadingController.create({
        message: 'Please wait...',
      });
      await loader.present();
      const userProfileDoc = await firebase.firestore().collection(`users`).doc(user.uid).get();
      if (userProfileDoc.exists) {
        const userProfile = {
          uid: userProfileDoc.id,
          ...userProfileDoc.data(),
        };
        await dispatch({
          type: TypeKeys.SET_USER_PROFILE,
          user: userProfile,
        });
      } else {
        await dispatch({
          type: TypeKeys.SET_USER_PROFILE,
          user: {
            uid: userProfileDoc.id,
          },
        });
      }
      await dispatch({
        type: TypeKeys.CHANGE_APP_AUTH_STATE,
        isLoggedIn: true,
      });

      await loader.dismiss();
    } else {
      dispatch({
        type: TypeKeys.CHANGE_APP_AUTH_STATE,
        isLoggedIn: false,
      });
      console.log('User not loggedin');
      // User is signed out.
    }
    dispatch({
      type: TypeKeys.CHANGE_APP_LOADING_STATE,
      appLoaded: true,
    });
  });
};

export const updateUserProfile = async (uid, dataToUpdate) => {
  const userRef = firebase.firestore().collection('users').doc(uid);
  await userRef.update(dataToUpdate);
};

export const updateCurrentUserProfile = async dataToUpdate => {
  const user = firebase.auth().currentUser;
  await updateUserProfile(user.uid, dataToUpdate);
};

export const updateSeamlessChargingProfile= async (uid, dataToUpdate) => {
  const userRef = firebase.firestore().collection('seamlesschargingusers').doc(uid);
  await userRef.update(dataToUpdate);
};

export const updateSeamlessChargingUser = async dataToUpdate => {
  const user = firebase.auth().currentUser;
  await updateSeamlessChargingProfile(user.uid, dataToUpdate);
};

export const getSeamlessChargingUser = async uid => {
  const userDoc = await firebase.firestore().collection('seamlesschargingusers').doc(uid).get();
  if (userDoc.exists) {
    return userDoc.data();
  }
  return null;
};

export const verifyEmailLinkAndAuthenticate = async (continueUrl, email) => {
  try {
    const isValid = firebase.auth().isSignInWithEmailLink(continueUrl);
    if (!isValid) {
      console.log('Not valid');
      return false;
    }

    await firebase.auth().signInWithEmailLink(email, continueUrl);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
